<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="success" v-on="on">Check your waiver status</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Waiver Check</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" fab small @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-card-text>
        <v-window v-model="window">
          <v-window-item :key="0">
            <v-row dense>
              <v-col cols="12">
                <div class="text-h6 mb-3">Let's start by finding your player profile</div>
                <player-lookup
                  @player-selected="selectPlayer"
                  :clearOnSelect="true"
                  ref="search"
                  :allowAdd="false"
                  @searching-change="onSearchingChange"
                ></player-lookup>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :key="1">
            <v-row dense>
              <v-col cols="12" v-if="selectedPlayer">
                <search-result-item
                  :key="selectedPlayer.id"
                  :player="selectedPlayer"
                ></search-result-item>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-fab-transition>
                  <v-progress-circular indeterminate color="success" v-if="loading"></v-progress-circular>
                  <v-chip :color="signed ? 'success': 'error'" class="white--text" large v-else>
                    Your waiver has {{ signed ? '' : 'not ' }} been signed
                  </v-chip>
                </v-fab-transition>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :key="2">
            <opt-in-sms-windows @verified="onSmsOptIn" :key="window === 2"></opt-in-sms-windows>
          </v-window-item>
          <v-window-item :key="3">
            <v-fab-transition>
              <div v-if="loading" class="text-center">
                <div class="text-h6">We are sending you the waiver...</div>
                <v-progress-circular indeterminate color="success"></v-progress-circular>
              </div>
              <div class="text-h6 text-center" v-else>
                We have just text you a link to the waiver.
                <br>Please sign it ASAP!
              </div>
            </v-fab-transition>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="min-height: 72px">
        <v-btn
          color="color3 color3Text--text"
          fab
          v-if="window > 0 && window < 3"
          @click.stop="window--"
        >
          <v-icon>fas fa-caret-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-fab-transition>
          <v-btn
            color="success white--text"
            v-if="!signed && window === 1 && !loading"
            @click.stop="window++"
          >Sign now</v-btn>
          <v-btn
            color="success white--text"
            v-if="window === 3"
            @click.stop="dialog = false"
          >Done</v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import PlayerLookup from '@/components/Player/Search/PlayerLookup'
import SearchResultItem from '@/components/Player/Search/SearchResultItem'
const OptInSmsWindows = () => import('@/components/Forms/OptInSmsWindows.vue')

export default {
  data () {
    return {
      dialog: false,
      window: 0,
      loading: false,
      selectedPlayer: null,
      pAgree: null
    }
  },
  computed: {
    ...mapGetters(['theme']),
    agreementId () {
      const p = this.theme.props.find(f => f.startsWith('agreement-'))
      const id = +p.replace('agreement-', '')
      return id
    },
    playerProfileId () {
      return this.selectedPlayer.id
    },
    signed () {
      return this.pAgree && this.pAgree.find(f => f.dtSigned)
    }
  },
  methods: {
    selectPlayer (player) {
      this.selectedPlayer = player
      this.loadStatuses()
      this.window = 1
    },
    loadStatuses () {
      this.loading = true
      this.$VBL.agreements.tournament.player(this.agreementId, this.playerProfileId)
        .then(r => {
          this.pAgree = r.data
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    },
    reset () {
      this.selectedPlayer = null
      this.pAgree = null
      this.window = 0
    },
    onSearchingChange (v) {
      this.loading = v
    },
    onSmsOptIn (number) {
      this.loading = true
      this.sendWaiver(number)
      this.window = 3
    },
    sendWaiver (number) {
      this.loading = true
      this.$VBL.agreements.tournament.sendSms(number, this.agreementId, this.playerProfileId)
        .then(r => {
          this.window = 3
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    window: function (v) {
      if (v === 0) {
        this.reset()
      }
    },
    dialog: 'reset'
  },
  components: {
    PlayerLookup,
    SearchResultItem,
    OptInSmsWindows
  }
}
</script>

<style lang="scss" scoped>

</style>
